import React from 'react'
import styled from 'styled-components'

import {ReactComponent as Advance} from '../assets/icons/advance.svg'
import {ReactComponent as Amazon} from '../assets/icons/amazon.svg'
import {ReactComponent as Android} from '../assets/icons/android.svg'
import {ReactComponent as Apple} from '../assets/icons/apple.svg'
import {ReactComponent as Archive} from '../assets/icons/archive.svg'
import {ReactComponent as ArrowDown} from '../assets/icons/arrow-down.svg'
import {ReactComponent as ArrowLeft} from '../assets/icons/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../assets/icons/arrow-right.svg'
import {ReactComponent as ArrowUp} from '../assets/icons/arrow-up.svg'
import {ReactComponent as BackHome} from '../assets/icons/back-home.svg'
import {ReactComponent as Balance} from '../assets/icons/balance.svg'
import {ReactComponent as Broom} from '../assets/icons/broom.svg'
import {ReactComponent as Bucket} from '../assets/icons/bucket.svg'
import {ReactComponent as Bug} from '../assets/icons/bug.svg'
import {ReactComponent as Calendar} from '../assets/icons/calendar.svg'
import {ReactComponent as Card} from '../assets/icons/cards.svg'
import {ReactComponent as Chat} from '../assets/icons/chat.svg'
import {ReactComponent as Check} from '../assets/icons/check.svg'
import {ReactComponent as CheckNarrow} from '../assets/icons/check-narrow.svg'
import {ReactComponent as CheckSquare} from '../assets/icons/check-square.svg'
import {ReactComponent as Clock} from '../assets/icons/clock.svg'
import {ReactComponent as Cog} from '../assets/icons/cog.svg'
import {ReactComponent as Contract} from '../assets/icons/contract.svg'
import {ReactComponent as Copy} from '../assets/icons/copy.svg'
import {ReactComponent as CreditCard} from '../assets/icons/credit-card.svg'
import {ReactComponent as Document} from '../assets/icons/document.svg'
import {ReactComponent as Dollar} from '../assets/icons/dollar.svg'
import {ReactComponent as Download} from '../assets/icons/download.svg'
import {ReactComponent as DspwNetwork} from '../assets/icons/dspw-network.svg'
import {ReactComponent as Edit} from '../assets/icons/pencil.svg'
import {ReactComponent as Empty} from '../assets/icons/empty.svg'
import {ReactComponent as Expand} from '../assets/icons/expand.svg'
import {ReactComponent as Eye} from '../assets/icons/eye.svg'
import {ReactComponent as EyeCrossed} from '../assets/icons/eye-crossed.svg'
import {ReactComponent as Flag} from '../assets/icons/flag.svg'
import {ReactComponent as Feather} from '../assets/icons/feather.svg'
import {ReactComponent as Filter} from '../assets/icons/filter.svg'
import {ReactComponent as FlashlightOff} from '../assets/icons/flashlight-off.svg'
import {ReactComponent as FlashlightOn} from '../assets/icons/flashlight-on.svg'
import {ReactComponent as FlexIn} from '../assets/icons/flex-in.svg'
import {ReactComponent as GeoferenceIn} from '../assets/icons/geofence-in.svg'
import {ReactComponent as GeoferenceOut} from '../assets/icons/geofence-out.svg'
import {ReactComponent as GraduationHat} from '../assets/icons/graduation-hat.svg'
import {ReactComponent as Group} from '../assets/icons/group.svg'
import {ReactComponent as Headset} from '../assets/icons/headset.svg'
import {ReactComponent as Image} from '../assets/icons/image.svg'
import {ReactComponent as Info} from '../assets/icons/info-circle.svg'
import {ReactComponent as Invoice} from '../assets/icons/invoice.svg'
import {ReactComponent as List} from '../assets/icons/list.svg'
import {ReactComponent as Locker} from '../assets/icons/locker.svg'
import {ReactComponent as Loadout} from '../assets/icons/loadout.svg'
import {ReactComponent as Lunch} from '../assets/icons/lunch.svg'
import {ReactComponent as LunchIn} from '../assets/icons/lunch-in.svg'
import {ReactComponent as LunchOut} from '../assets/icons/lunch-out.svg'
import {ReactComponent as Maintenance} from '../assets/icons/maintenance.svg'
import {ReactComponent as Marker} from '../assets/icons/marker.svg'
import {ReactComponent as Menu} from '../assets/icons/menu.svg'
import {ReactComponent as Megaphone} from '../assets/icons/megaphone.svg'
import {ReactComponent as Move} from '../assets/icons/move.svg'
import {ReactComponent as Mute} from '../assets/icons/mute.svg'
import {ReactComponent as NotAllowed} from '../assets/icons/not-allowed.svg'
import {ReactComponent as Notes} from '../assets/icons/notes.svg'
import {ReactComponent as Package} from '../assets/icons/package.svg'
import {ReactComponent as PackageBack} from '../assets/icons/package-back.svg'
import {ReactComponent as PackageCheck} from '../assets/icons/package-check.svg'
import {ReactComponent as PackageTimes} from '../assets/icons/package-times.svg'
import {ReactComponent as Parking} from '../assets/icons/parking.svg'
import {ReactComponent as ParkingGeoIn} from '../assets/icons/parking-geofence-in.svg'
import {ReactComponent as ParkingGeoOut} from '../assets/icons/parking-geofence-out.svg'
import {ReactComponent as Pause} from '../assets/icons/pause.svg'
import {ReactComponent as Phone} from '../assets/icons/phone.svg'
import {ReactComponent as Person} from '../assets/icons/person.svg'
import {ReactComponent as Play} from '../assets/icons/play.svg'
import {ReactComponent as Plus} from '../assets/icons/plus.svg'
import {ReactComponent as PunchIn} from '../assets/icons/punch-in.svg'
import {ReactComponent as PunchOut} from '../assets/icons/punch-out.svg'
import {ReactComponent as Printer} from '../assets/icons/printer.svg'
import {ReactComponent as Radius} from '../assets/icons/radius.svg'
import {ReactComponent as Refresh} from '../assets/icons/refresh.svg'
import {ReactComponent as Replace} from '../assets/icons/replace.svg'
import {ReactComponent as Rts} from '../assets/icons/rts.svg'
import {ReactComponent as Search} from '../assets/icons/search.svg'
import {ReactComponent as Segment} from '../assets/icons/segment.svg'
import {ReactComponent as Skill} from '../assets/icons/skills.svg'
import {ReactComponent as Target} from '../assets/icons/target.svg'
import {ReactComponent as TimeLine} from '../assets/icons/timeline.svg'
import {ReactComponent as Times} from '../assets/icons/times.svg'
import {ReactComponent as TimesNarrow} from '../assets/icons/times-narrow.svg'
import {ReactComponent as Trash} from '../assets/icons/trash.svg'
import {ReactComponent as TrendDown} from '../assets/icons/trend-down.svg'
import {ReactComponent as TrendUp} from '../assets/icons/trend-up.svg'
import {ReactComponent as Trophy} from '../assets/icons/trophy.svg'
import {ReactComponent as Upload} from '../assets/icons/upload.svg'
import {ReactComponent as VehicleOil} from '../assets/icons/vehicle-oil.svg'
import {ReactComponent as Volume} from '../assets/icons/volume.svg'
import {ReactComponent as Warning} from '../assets/icons/warning.svg'

import theme from '../Theme'

const Wrapper = (Element) => {
  const Svg = styled(Element)`
        width: ${props => props.size ? props.size : '40px'}
        height: ${props => props.size ? props.size : '40px'}

        ${props => props.onClick && `
        cursor: pointer;
        `}

        path {
            fill:${props => props.color ? props.color + ' !important' : theme.colors.secondary};
        }
    `
  return Svg
}

let Icons = {
  Advance,
  Amazon,
  Android,
  Apple,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BackHome,
  Balance,
  Broom,
  Bucket,
  Bug,
  Chat,
  Calendar,
  Card,
  Check,
  CheckNarrow,
  Contract,
  Copy,
  CreditCard,
  CheckSquare,
  Clock,
  Cog,
  Dollar,
  Download,
  DspwNetwork,
  Document,
  Edit,
  Empty,
  Expand,
  Eye,
  EyeCrossed,
  Feather,
  Flag,
  Filter,
  FlashlightOff,
  FlashlightOn,
  FlexIn,
  GeoferenceIn,
  GeoferenceOut,
  GraduationHat,
  Group,
  Headset,
  Image,
  Info,
  Invoice,
  List,
  Locker,
  Loadout,
  Lunch,
  LunchIn,
  LunchOut,
  Maintenance,
  Marker,
  Menu,
  Megaphone,
  Move,
  Mute,
  NotAllowed,
  Notes,
  Pause,
  Package,
  PackageBack,
  PackageCheck,
  PackageTimes,
  Parking,
  ParkingGeoIn,
  ParkingGeoOut,
  Phone,
  Person,
  Play,
  Plus,
  Printer,
  PunchIn,
  PunchOut,
  Radius,
  Refresh,
  Replace,
  Rts,
  Segment,
  Search,
  Skill,
  Target,
  TimeLine,
  Times,
  TimesNarrow,
  Trash,
  TrendDown,
  TrendUp,
  Trophy,
  Upload,
  VehicleOil,
  Volume,
  Warning
}
let Items = Object.keys(Icons).sort((a, b) => a.localeCompare(b))
Items.map(key => {
  Icons[key] = Wrapper(Icons[key])
})

export default Icons
